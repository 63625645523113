import '../App.css';

import React from 'react';

import {
    Row,
    Col,
} from 'react-bootstrap';

const TuMeta = () => (
    <>
        <Row>
            <Col>
                <div className="text-center d-flex my-auto" style={{ height: '156px' , width: '350px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '15px'}}>
                    <img alt="Logo" className="my-auto" style={{ height: '130px', marginLeft: 'auto', marginRight: 'auto' }} src="https://static.wixstatic.com/media/840964_d1d2dbc61c494e9697c902d6d9493409~mv2.png/v1/crop/x_38,y_175,w_433,h_161/fill/w_182,h_66,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/1%5B1%5D.png" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    Desarrollo de App para caja de ahorro. Implementación de core de crédito y ahorro. Consumo de servicios web REST bancarios. NodeJS. 
                </p>
            </Col>
        </Row>
    </>
);

export default TuMeta;