import '../App.css';

import React from 'react';

import {
    Row,
    Col,
} from 'react-bootstrap';

const MNA = () => (
    <>
        <Row>
            <Col>
                <div className="text-center d-flex" style={{ backgroundColor: '#000', height: '156px', maxWidth: '650px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '15px' }}>
                    <img className="my-auto" alt="Logo" style={{ width: '80%', height: '40%', marginLeft: 'auto', marginRight: 'auto' }} src="https://mna.inah.gob.mx/images/home/logomna.png" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    Éste fue mi primer trabajo como desarrollador web. Aquí mismo hice mi servicio social, después del cual me contrataron.
                </p>
                <p>
                    Durante tres años mis labores fueron el mantenimiento de la página web, el desarrollo nuevas características del CMS 
                    propietario, así como el desarrollo de sistemas internos.
                </p>
            </Col>
        </Row>
    </>
);

export default MNA;