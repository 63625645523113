import '../App.css';

import React from 'react';


import {
    Row,
    Col,
} from 'react-bootstrap';

import LogoPHP from '../assets/img/PHP-logo.svg';

const PHP = () => (
    <>
        <Row>
            <Col>
                <div className="text-center">
                    <img style={{ height: '156px', marginTop: '15px', marginBottom: '15px' }} src={LogoPHP} alt="Logo" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    Tengo más de 7 años de experiencia desarrollando y dando mantenimiento a sistemas con esta tecnología.
                </p>
                <p>
                    Así mismo, tengo experiencia en desarrollo e implementación de APIs SOAP y REST de distintas compañías.
                </p>
                <p>
                    La mayoría de estos sistemas han sido de caracter interno, por lo que no se puede ver mi trabajo
                    en este aspecto salvo en la página del <a href="https://mna.inah.gob.mx">Museo Nacional de Antropología</a>
                </p>
            </Col>
        </Row>
    </>
);

export default PHP;