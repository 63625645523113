import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";


import Home from './pages/Home';
import GastitosAvisoPrivacidad from './pages/GastitosAvisoPrivacidad';


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/gastitos_app_privacidad" element={<GastitosAvisoPrivacidad />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
