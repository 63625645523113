import '../App.css';

import React from 'react';

import {
    Row,
    Col,
} from 'react-bootstrap';

import LogoSQLs from '../assets/img/MySQL-and-SQL-Server.png';

const Databases = () => (
    <>
        <Row>
            <Col>
                <div className="text-center">
                    <img style={{ height: '156px', marginTop: '15px', marginBottom: '15px' }} src={LogoSQLs} alt="Logo" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    Uso de manejadores de bases de datos SQL como MySQL y Microsoft SQL Server en diversos 
                    proyectos tanto internos de las organizaciones como personales
                </p>
            </Col>
        </Row>
    </>
);

export default Databases;