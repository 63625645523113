import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import './css/footer.css';

const Footer = () => {
    let today = new Date();

    let mes = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
    ];

    let dias = [
        'domingo',
        'lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sabado',
    ];

    return (<>
        <Container id="Footer" fluid>
            <Container className="p-4">
                <Row>
                    <Col lg={4} md={12} >
                        <h5>Lugares de interés</h5>
                        <ul>
                            <li>
                                <a href="https://github.com/CesarGascaAndrade" target="_blank" rel="noreferrer">GitHub</a>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=cesar.gasca.gastitos" target="_blank" rel="noreferrer">Mi App Gastitos</a>
                            </li>
                        </ul>
                    </Col>
                    <Col lg={4} md={6}>
                        <h5>Contacto</h5>
                        <ul>
                            <li>
                                <a href="mailto:cesar.gasca.andrade@gmail.com">cesar.gasca.andrade@gmail.com</a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/cesar-gasca-30758a60" target="_blank" rel="noreferrer">LinkedIn</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>

        </Container>
        <Container style={{ backgroundColor: '#000' }} fluid>
            <Row>
                <Col>
                    <p className="text-center white-text">Hoy es {dias[today.getDay()]} {today.getDate()} de {mes[today.getMonth()]} del {today.getFullYear()}... y sereno</p>
                </Col>
            </Row>
        </Container>
    </>);
};

export default Footer;