import '../App.css';

import React from 'react';

import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';

import Header from './common/Header';
import Footer from './common/Footer';

const PHP = () => (
    <>
        <Header />
        <Container>
            <Row>
                <Col>
                    <h1 class="text-center">Gastitos APP</h1>
                    <h2 class="text-center">Política de privacidad</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        Estos términos y condiciones de privacidad establecen los lineamientos y prácticas de uso de datos de la aplicación <a href="https://play.google.com/store/apps/details?id=cesar.gasca.gastitos" target="_blank">Gastitos</a>.
                    </p>
                    <p>
                        Gastitos es una aplicación para llevar el registro de los ingresos y los egresos de dinero en la vida diaria. El autor (<a href="https://cesargasca.com" target="_blank">César Gasca</a>) inició este proyecto con el propósito de aprender el proceso completo de desarrollo de apps desde la concepción de la idea hasta la publicación en Google Play Store.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ol>
                        <li>
                            <h5>¿Cual es la información que recopila la app?</h5>
                            <p>La app no recopila datos personales. Solamente recibe registros manuales (efectuados por quién usa la app) de ingresos o egresos.</p>
                        </li>
                        <li>
                            <h5>¿Donde se guardan los registros?</h5>
                            <p>La app utiliza un motor de base de datos llamado <a href="https://www.sqlite.org/index.html">SQLite</a></p>
                        </li>
                        <li>
                            <h5>¿La información será compartida con alguien?</h5>
                            <p>NO. La aplicación no comparte información con ninguna persona o empresa.</p>
                        </li>
                        <li>
                            <h5>¿Se utilizan cookies u otras tecnologías de seguimiento?</h5>
                            <p>NO. La aplicación no usa ninguna tecnología de seguimiento.</p>
                        </li>
                        <li>
                            <h5>¿Existe la versión web o versión de escritorio de esta app?</h5>
                            <p>Por el momento no.</p>
                        </li>
                        <li>
                            <h5>¿Como puede comunicarse con el autor sobre estos términos y condiciones?</h5>
                            <p>Si tiene preguntas o comentarios sobre estos términos y condiciones, puedes enviar un correo a <a href="mailto:cesar.gasca.andrade@gmail.com">cesar.gasca.andrade@gmail.com</a></p>
                        </li>
                    </ol>
                </Col>
            </Row>
        </Container>
        <Footer />
    </>
);

export default PHP;