import '../App.css';

import React from 'react';

import {
    Row,
    Col,
} from 'react-bootstrap';

const GrupoPromass = () => (
    <>
        <Row>
            <Col>
                <div className="text-center d-flex my-auto" style={{ backgroundColor: '#0b1c38', height: '156px' , width: '350px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '15px'}}>
                    <img alt="Logo" className="my-auto" style={{ height: '130px', marginLeft: 'auto', marginRight: 'auto' }} src="https://grupopromass.com/assets/images/logo.png" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    Desarrollo y mantenimiento de sistemas de la empresa. Desarrollo y mantenimiento de API's REST y SOAP. 
                </p>
            </Col>
        </Row>
    </>
);

export default GrupoPromass;