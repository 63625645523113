import './css/header.css';

import background from '../../assets/header_background.jpg';

import { Parallax } from 'react-parallax';

const Header = () => (
    <Parallax
        blur={0}
        bgImage={background}
        bgImageAlt="the cat"
        strength={200}
    >
        <div style={{ height: '150px' }} />
        <h1 className="text-center white-text green-screen-font">César Gasca</h1>
        <h2 className="text-center white-text green-screen-font">💻 Viendo la matrix mientras tomo una taza café ☕</h2>
        <div style={{ height: '150px' }} />
    </Parallax>
);

export default Header;