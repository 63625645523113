import '../App.css';

import React from 'react';


import {
    Row,
    Col,
} from 'react-bootstrap';

import LogoFlutter from '../assets/img/flutter-logo.svg';


import Counters from '../assets/img/apps/CountersFlutter.jpeg';

const Flutter = () => (
    <>
        <Row>
            <Col>
                <div className="text-center">
                    <img style={{ height: '156px', marginTop: '15px', marginBottom: '15px' }} src={LogoFlutter} alt="Logo" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <p className="text-center">
                    Desarrollo de proyectos personales para aprender esta tecnología.
                    Personalmente me gusta mucho esta tecnología, pero veo que si no se
                    tienen buenas prácticas de programación o un buen entendimiento de
                    patrones de diseño, el mantenimiento de una aplicación con esta
                    tecnología se volvería problemático y limitaría la escalabilidad.
                </p>
                <p className="text-center">
                    <a href="https://github.com/CesarGascaAndrade/Gastitos.Flutter" target="_blank" rel="noreferrer">
                        Gastitos.Flutter (en proceso)
                    </a>
                </p>
                <p className="text-center">
                    <a href="https://github.com/CesarGascaAndrade/Counters.Flutter" target="_blank" rel="noreferrer">Counters.Flutter</a>
                </p>
                <p className="text-center">
                    <img src={Counters} style={{ width: '33%' }} alt="Pantalla 1" />
                </p>
                <p className="text-center">
                    <a href="https://github.com/CesarGascaAndrade/SquareRT.Flutter" target="_blank" rel="noreferrer">SquateRT.Flutter</a>
                </p>

            </Col>
        </Row>
    </>
);

export default Flutter;