import '../App.css';

import React from 'react';

import {
    Row,
    Col,
} from 'react-bootstrap';

import LogoReactNative from '../assets/img/react-native-1.svg';

import Gastitos1 from '../assets/img/apps/GastitosReactNative1.jpeg';
import Gastitos2 from '../assets/img/apps/GastitosReactNative2.jpeg';
import Gastitos3 from '../assets/img/apps/GastitosReactNative3.jpeg';

const ReactNative = () => (
    <>
        <Row>
            <Col>
                <div className="text-center">
                    <img style={{ height: '156px', marginTop: '15px', marginBottom: '15px' }} src={LogoReactNative} alt="Logo" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    Una tecnología muy fácil de entender si vienes de ReactJS o Flutter. Tienen una folosofía de desarrollo muy parecida.
                </p>
                <p className="text-center">
                    <a href="https://github.com/CesarGascaAndrade/Gastitos.ReactNative">Gastitos.Flutter</a>
                </p>
                <p className="text-center">
                    <img src={Gastitos1} style={{ width: '33%' }} alt="Pantalla 1"/>
                    <img src={Gastitos2} style={{ width: '33%' }} alt="Pantalla 2" />
                    <img src={Gastitos3} style={{ width: '33%' }} alt="Pantalla3" />
                </p>
            </Col>
        </Row>
    </>
);

export default ReactNative;