import '../App.css';

import React, { useState } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Modal
} from 'react-bootstrap';


import LogoPHP from '../assets/img/PHP-logo.svg';
import LogoJS from '../assets/img/logo-javascript.svg';
import LogoDB from '../assets/img/sql-database-generic.svg';
import LogoReactNative from '../assets/img/react-native-1.svg';
import LogoFlutter from '../assets/img/flutter-logo.svg';
import LogoGFC from '../assets/img/gfc_capital.webp';
import LogoPromass from '../assets/img/gpmass.png';
import LogoUPIICSA from '../assets/img/UPIICSA.png';
import LogoEDX from '../assets/img/edx.png';
import LogoVMEdu from '../assets/img/vmedu.png';

import Php from './Php';
import JS from './Javascript';
import DataBases from './Databases';
import ReactNative from './ReactNative';
import Flutter from './Flutter';

import TuMeta from './TuMeta';
import GrupoPromass from './GrupoPromass';
import MNA from './MNA';

import background from '../assets/header_background.jpg';

import Header from './common/Header';
import Footer from './common/Footer';

import { Parallax } from 'react-parallax';

const Home = () => {
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('Title');
    const [modalContent, setModalContent] = useState('Content');

    const handleClose = () => setShow(false);
    const handleShow = (title, content) => {
        setModalTitle(title);
        setModalContent(content);

        setShow(true);
    };

    return (
        <>
            <Header />
            <Container>
                <Row>
                    <Col>
                        <div style={{ height: '50px' }} />
                        <p>Programador egresado de IPN-UPIICSA, de la carrera de Licenciatura en Ciencias de la Informática. Elegí ser desarrollador de sistemas porque me gusta la programación y mi objetivo es desarrollar sistemas que aumenten la productividad de las personas.</p>
                        <p>Cuento con experiencia en: </p>
                        <p>
                            <ul>
                                <li>Desarrollo, mantenimiento e implementación de sistemas</li>
                                <li>PHP</li>
                                <li>HTML</li>
                                <li>JavaScript</li>
                                <li>NodeJS</li>
                                <li>MySQL</li>
                                <li>SQL Server</li>
                                <li>CSS</li>
                                <li>Power BI</li>
                                <li>Microsoft Azure</li>
                                <li>Desarrollo móvil con React Native y Flutter</li>
                            </ul>
                        </p>
                        <div style={{ height: '50px' }} />
                    </Col>
                </Row>
            </Container>
            <Parallax
                blur={0}
                bgImage={background}
                bgImageAlt="the cat"
                strength={200}
            >
                <div style={{ height: '150px' }} />
                <h1 className="text-center white-text green-screen-font">
                    Desarrollo WEB
                </h1>
                <div style={{ height: '150px' }} />
            </Parallax>
            <Container>
                <Row className="justify-content-md-center">
                    <div style={{ height: '100px' }} />
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('PHP', Php);
                        }}>
                            <Card.Img variant="top" src={LogoPHP} alt="PHP" />
                            <Card.Body>
                                <Card.Title>PHP</Card.Title>
                                <Card.Text>
                                    Más de 7 años de experiencia
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('JavaScript', JS);
                        }}>
                            <Card.Img variant="top" src={LogoJS} alt="JS"/>
                            <Card.Body>
                                <Card.Title>JavaScript</Card.Title>
                                <Card.Text>
                                    Domionio en frontend y backend
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('Bases de Datos SQL', DataBases);
                        }}>
                            <Card.Img variant="top" src={LogoDB} alt="DB" />
                            <Card.Body>
                                <Card.Title>Bases de Datos SQL</Card.Title>
                                <Card.Text>
                                    MySQL y MS SQL Server
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <div style={{ height: '50px' }} />
                </Row>
            </Container>
            <Parallax
                blur={0}
                bgImage={background}
                bgImageAlt="the cat"
                strength={200}
            >
                <div style={{ height: '150px' }} />
                <h1 className="text-center white-text green-screen-font">
                    Desarrollo Móvil
                </h1>
                <div style={{ height: '150px' }} />
            </Parallax>
            <Container>
                <Row className="justify-content-md-center">
                    <div style={{ height: '100px' }} />
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('React Native', ReactNative);
                        }}>
                            <Card.Img variant="top" src={LogoReactNative} alt="ReactNative" />
                            <Card.Body>
                                <Card.Title>React Native</Card.Title>
                                <Card.Text>
                                    Con un par de apps personales en GitHub
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('Flutter', Flutter);
                        }}>
                            <div className="text-center">
                                <img className="card-img-top" style={{ height: '156px' }} src={LogoFlutter} alt="Flutter" />
                            </div>
                            <Card.Body>
                                <Card.Title>Flutter</Card.Title>
                                <Card.Text>
                                    Muy bueno, pero peligroso si es código espagetti
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <div style={{ height: '50px' }} />
                </Row>
            </Container>
            <Parallax
                blur={0}
                bgImage={background}
                bgImageAlt="the cat"
                strength={200}
            >
                <div style={{ height: '150px' }} />
                <h1 className="text-center white-text green-screen-font">
                    Experiencia Laboral
                </h1>
                <div style={{ height: '150px' }} />
            </Parallax>
            <Container>
                <Row className="justify-content-md-center">
                    <div style={{ height: '100px' }} />
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('TuMeta', TuMeta);
                        }}>
                            <div className="text-center d-flex" style={{ height: '156px' }}>
                                <img className="my-auto" alt="Grupo PROMASS" style={{ marginLeft: 'auto', marginRight: 'auto' }} src={LogoGFC} />
                            </div>
                            <Card.Body>
                                <Card.Title>GFC Capital</Card.Title>
                                <Card.Text>
                                    Agosto 2022 - Actualidad : Tech Lead
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('Grupo PROMASS / Protec', GrupoPromass);
                        }}>
                            <div className="text-center d-flex" style={{ height: '156px' }}>
                                <img className="my-auto" alt="Grupo PROMASS" style={{ height: '130px', marginLeft: 'auto', marginRight: 'auto' }} src={LogoPromass} />
                            </div>
                            <Card.Body>
                                <Card.Title>Grupo PROMASS / Protec</Card.Title>
                                <Card.Text>
                                    Febrero 2016 - Agosto 2022 : Programador
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('Museo Nacional de Antropología', MNA);
                        }}>
                            <div className="text-center d-flex" style={{ backgroundColor: '#000', height: '156px' }}>
                                <img className="my-auto" alt="MNA-INAH" style={{  height: '50px', marginLeft: 'auto', marginRight: 'auto' }} src="https://mna.inah.gob.mx/images/home/logomna.png" />
                            </div>
                            <Card.Body>
                                <Card.Title>Museo Nacional de Antropología</Card.Title>
                                <Card.Text>
                                    Marzo 2013 - Enero 2016 : Programador
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <div style={{ height: '50px' }} />
                </Row>
            </Container>
            <Parallax
                blur={0}
                bgImage={background}
                bgImageAlt="the cat"
                strength={200}
            >
                <div style={{ height: '150px' }} />
                <h1 className="text-center white-text green-screen-font">
                    Estudios
                </h1>
                <div style={{ height: '150px' }} />
            </Parallax>
            <Container>
                <Row className="justify-content-md-center">
                    <div style={{ height: '150px' }} />
                    <Col sm={12} lg={4}>
                        <Card onClick={() => {
                            handleShow('IPN - UPIICSA', 'Estudios');
                        }}>
                            <div className="text-center d-flex" style={{ height: '156px' }}>
                                <img className="my-auto" style={{ height: '120px', marginLeft: 'auto', marginRight: 'auto' }} src={LogoUPIICSA} alt="IPN UPIICSA" />
                            </div>
                            <Card.Body>
                                <Card.Title>IPN - UPIICSA</Card.Title>
                                <Card.Text>
                                    2006 - 2014 Lic. en Ciencias de la Informática
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Card>
                            <div className="text-center d-flex" style={{ height: '156px' }}>
                                <img className="my-auto" style={{ height: '120px', marginLeft: 'auto', marginRight: 'auto' }} src={LogoEDX} alt="edx" />
                            </div>
                            <Card.Body>
                                <Card.Title>edX online</Card.Title>
                                <Card.Text>
                                    Curso online impartido por el MIT (si, ese mero)
                                </Card.Text>
                                <div className="d-grid gap-2">
                                    <Button href="https://s3.amazonaws.com/verify.edx.org/downloads/12db0f88179248d097f008d88d405a79/Certificate.pdf" variant="primary">Ver certificado</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} lg={4}>
                        <Card>
                            <div className="text-center d-flex" style={{ height: '156px' }}>
                                <img className="my-auto" style={{ height: '120px', marginLeft: 'auto', marginRight: 'auto' }} src={LogoVMEdu} alt="VMEdu" />
                            </div>
                            <Card.Body>
                                <Card.Title>VMEdu - SCRUMstudy</Card.Title>
                                <Card.Text>
                                     Cursado por Grupo PROMASS
                                </Card.Text>
                                <div className="d-grid gap-2">
                                    <Button href="http://81cd1176253f3f59d435-ac22991740ab4ff17e21daf2ed577041.r77.cf1.rackcdn.com/Certificates/ScrumFundamentalsCertified-CesarJosueGascaAndrade-645594.pdf" variant="primary">Scrum Fundamentals Certified</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Footer />
        </>
    )
};




export default Home;