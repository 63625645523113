import '../App.css';

import React from 'react';


import {
    Row,
    Col,
} from 'react-bootstrap';

import LogoJS from '../assets/img/logo-javascript.svg';

const JavaScript = () => (
    <>
        <Row>
            <Col>
                <div className="text-center">
                    <img style={{ height: '156px', marginTop: '15px', marginBottom: '15px' }} src={LogoJS} alt="Logo"/>
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    Con experiencia en desarrollo de frontent (ReactJS, React Native, jQuery) y backend (NodeJS, Express).
                </p>
            </Col>
        </Row>
    </>
);

export default JavaScript;